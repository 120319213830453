<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible :trigger="null">
      <div class="logo"/>
      <a-menu v-model:selectedKeys="selectedKeys" mode="inline" theme="dark">
        <a-menu-item key="defaultpath">
          <home-outlined/>
          <router-link to="/"> 首页</router-link>
        </a-menu-item>
        <a-sub-menu key="2">
          <template #title>
            <span>
              <span>后台管理</span>
            </span>
          </template>
          <a-menu-item key="adminList">
            <router-link to="/adminList">管理员列表</router-link>
          </a-menu-item>
          <a-menu-item key="userlist">
            <router-link to="/userlist">用户列表</router-link>
          </a-menu-item>
          <a-menu-item key="appuser_list">
            <router-link to="/appuser_list">商城用户列表</router-link>
          </a-menu-item>
          <a-menu-item key="checkEmploye_list">
            <router-link to="/checkEmploye_list">企业用户认证</router-link>
          </a-menu-item>
          <!--          <a-menu-item key="project_tags">-->
          <!--            <router-link to="/project_tags">标签管理</router-link>-->
          <!--          </a-menu-item>-->
        </a-sub-menu>
        <a-sub-menu key="3">
          <template #title>
            <span>
              <span>商城管理</span>
            </span>
          </template>
          <a-menu-item key="category_type">
            <router-link to="/category_type">产品Tag管理</router-link>
          </a-menu-item>
          <a-menu-item key="sku_type">
            <router-link to="/sku_type">sku分类管理</router-link>
          </a-menu-item>

          <a-menu-item key="products">
            <router-link to="/products">商品管理</router-link>
          </a-menu-item>
          <a-menu-item key="windows">
            <router-link to="/windows">橱窗管理</router-link>
          </a-menu-item>
          <a-menu-item key="ad_space">
            <router-link to="/ad_space">广告位</router-link>
          </a-menu-item>
          <a-menu-item key="coupons">
            <router-link to="/coupons_list">优惠卷管理</router-link>
          </a-menu-item>
          <a-menu-item key="message_list">
            <router-link to="/message_list">消息管理</router-link>
          </a-menu-item>

        </a-sub-menu>
        <a-sub-menu key="4">
          <template #title>
            <span>
              <span>仓库管理</span>
            </span>
          </template>
          <a-menu-item key="storeroom_type">
            <router-link to="/storeroom_type">仓库管理</router-link>
          </a-menu-item>
          <!--          <a-menu-item key="inventoryLog">-->
          <!--            <router-link to="/inventoryLog">批次管理</router-link>-->
          <!--          </a-menu-item>-->
          <a-menu-item key="orderslist">
            <router-link to="/orderslist">订单管理</router-link>
          </a-menu-item>
          <a-menu-item key="team_list">
            <router-link to="/team_list">团购订单管理</router-link>
          </a-menu-item>
          <a-menu-item key="orderslist_point">
            <router-link to="/orderslist_point">积分订单管理</router-link>
          </a-menu-item>


        </a-sub-menu>
        <a-sub-menu key="5">
          <template #title>
            <span>
              <span>文章评论管理</span>
            </span>
          </template>
          <a-menu-item key="article_list">
            <router-link to="article_list">文章列表</router-link>
          </a-menu-item>
<!--          <a-menu-item key="comment_list">-->
<!--            <router-link to="comment_list">评论列表</router-link>-->
<!--          </a-menu-item>-->

        </a-sub-menu>


        <a-menu-item key="9" @click="onLogout">
          <export-outlined/>
          <span>退出</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <!--            内部嵌入页面-->
      <router-view/>
      <a-layout-footer style="text-align: center">
        这里是页脚
<!--        <audio ref="audio" id="audio" muted="muted">-->
<!--          <source src="http://project.6cd.com.cn:31000/img/y1532.mp3">-->
<!--        </audio>-->
      </a-layout-footer>
    </a-layout>

  </a-layout>

</template>
<script>

import {Modal} from "ant-design-vue";
// import { useRouter } from "vue-router";
import {HomeOutlined, ExportOutlined} from '@ant-design/icons-vue';
import Storage from "../common/storage";
import moment from "moment";
import {notification} from 'ant-design-vue';
import Config from "@/config";

export default ({
  setup() {

  },
  components: {
    HomeOutlined,
    ExportOutlined
  },
  created() {
    console.log("Storage: ", Storage);
    console.log("token_expire_time: ", Storage.gtjn_token_expire_time);
    console.log("token_expire_time: ", moment().unix());
    // if(Storage.gtjn_token_expire_time > moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')){
    if (Storage.gtjn_token_expire_time > moment().unix()) {
      console.log("登陆有效");
      // this.$router.push({path: "/"});
    } else {
      Storage.gtjn_token = "";
      this.$router.push({path: "/login"});
    }

    if (Storage.gtjn_token === "") {
      this.$router.push({path: "/login"});
    }
    // const roles = Storage.gtjn_roles
    // console.log('当前用户权限组1',Storage.gtjn_roles);
    // const myroles = roles.split(',').map(Number);
    //
    // console.log('当前用户权限组2',myroles);
    // if(myroles.includes(1)===true){
    //   this.$router.push({path: "/"});
    //   console.log("权限2", true);
    //   return;
    // }
  },
  mounted() {
    this.timer = setInterval(() => {
      // this.getnotificationlist(1, 5, 1)
      // console.log('获取通知');
      //处理部分
    }, 60000) //1分钟
  },
  beforeUnmount() {
    clearInterval(this.timer)
    this.timer = null;
  },
  data() {
    return {
      collapsed: false,
      web_url: Config.data.web_url,
      selectedKeys: ['defaultpath'],
      permissiondata: [],
      loading: false,
      timer: null,
    };
  },
  methods: {
    flushCom(to) {
      console.log('to', to);
      console.log('from', this.$route.query);

    },
    // async getprojectList() {
    //   console.log('getprojectList');
    //   this.loading = true;
    //   this.projectdata = [];
    //   let result = await this.Axios.post("/project/list", {
    //     uid: Storage.gtjn_uid,
    //     token: Storage.gtjn_token,
    //     customer_id: Storage.gtjn_customer_id,
    //
    //   });
    //   if (result.status === true) {
    //
    //     this.projectdata = result.data
    //     // console.log("sessionStorage projectdata", sessionStorage.gtjn_projectdata);
    //   }
    //   if (result.status === false) {
    //     Modal.error({
    //       title: result.msg
    //     });
    //   }
    //   this.loading = false;
    // },
    onLogout() {
      const that = this;
      // const router = useRouter();
      Modal.confirm({
        title: "退出",
        content: "是否退出登录?",
        onOk() {
          Storage.gtjn_token = "";
          Storage.gtjn_token_expire_time = "";
          that.$router.push({path: "/login"});
        }
      });
    },
    Play() {
      let audioPlay = this.$refs.audio;
      audioPlay.src = this.web_url + '/img/y1532.mp3'  //本地音频路劲
      audioPlay.type = "audio/mpeg"
      audioPlay.play()
      audioPlay.onended = function () {
        audioPlay.play();
      }
    },
    async getnotificationlist(page, limit, is_only_unread,) {

      this.loading = true;
      let result = await this.Axios.post("/notification/list", {
        uid: Storage.gtjn_uid,
        token: Storage.gtjn_token,
        customer_id: Storage.gtjn_customer_id,
        page: page,
        limit: limit,
        is_only_unread: is_only_unread,
        top_project_id: this.project_id,
        created_at: Storage.gtjn_notification_expire_time,
      });
      if (result.status === true) {
        // let data = [];
        for (let i in result.data.list) {
          let info = result.data.list[i];
          // setTimeout(function () {
          //   this.notification(
          //       info.data.title,
          //       info.data.content,
          //       info.notifiable_id,
          //   )
          // }, 1000 * i);	// 还是每秒执行一次，不是累加的
          Storage.gtjn_notification_expire_time = moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')

          this.notification(
              info.data.title,
              info.data.content,
              info.notifiable_id,
          )
          // this.Play()
        }
      }
      if (result.status === false) {
        Modal.error({
          title: result.msg
        });
      }
      this.loading = false;
    },
    notification(Title, description, id) {
      console.log('点击了', Title);
      console.log('点击了', description);
      console.log('点击了', id);
      notification.open({
        message: Title,
        description: description,
        type: 'warning',
        onClick: () => {
          console.log('点击了', id);
        },
      });
    },
  }
});
</script>
<style>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>