import {createApp} from 'vue'
import { nextTick } from "@vue/runtime-core"
import App from './App.vue'
import router from './router'
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import * as Icons from '@ant-design/icons-vue'

// antds
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import moment from 'moment'
import 'moment-timezone'; // 引入moment-timezone
moment.locale('zh-cn');
// 设置Moment.js的默认时区为东八区（北京时间）
moment.tz.setDefault('Asia/Shanghai');
// 网络部分
import Axios from 'axios'
/* eslint-disable no-new */
import Config from './config'
import "echarts";
import ECharts from 'vue-echarts'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
const app = createApp(App);

app.use(router).mount('#app')
app.use(Viewer, {
    defaultOptions: {
        // 需要配置的属性 注意属性并没有引号
        title: false,
        toolbar: false
    }
})
app.config.globalProperties.Axios=Axios //全局配置axios
app.use(Antd);
nextTick(() => {
    // 配置全局对象
    app.config.globalProperties.$icons = Icons
    // Antd 注入全部图标（这样注入之后，就可以全局直接使用 icon 组件，不需要每个页面去引入了）
    for (const key in Icons) { app.component(key, Icons[key]) }
})
app.component('v-chart', ECharts)

app.config.productionTip = false
app.config.globalProperties.$moment = moment
/* eslint-disable no-new */

Axios.defaults.baseURL = Config.data.server_url
// Axios.defaults.headers.$post['Content-Type'] = 'application/x-www-form-urlencoded'
// Axios.defaults.headers.$get['Content-Type'] = 'application/x-www-form-urlencoded'
Axios.interceptors.request.use(config => {
    // 为请求头添加x-access-token字段为服务端返回的token
    config.headers['Accept'] = 'application/json'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // return config是固定用法 必须有返回值
    return config
})

// app.prototype.get = Axios.get
// app.prototype.post = Axios.post
app.config = Config.data
Axios.interceptors.response.use(response => {
    function gotoLogin() {
        if (window.app.$router.history.current.fullPath !== "./#/login") {
            window.app.$router.push({path: "./#/login"});
        }
    }

    if (response.status !== 200) {
        console.log('状态错误跳转到 登录', response.status)
        gotoLogin()
        return {code: -1}
    }

    // 正常结果
    if (response.data.data === "404 page not found") {
        Modal.error({
            title: "api服务器接口错误"
        });
        return
    }
    if (response.data.code === 200) {

        return response.data;
    }

    // 不正常结果

    // if (response.data.code == -1) {
        // this.$message.error(response.data.msg);
        // alert(response.data.msg)
        // message.config({
        //     top: `100px`,
        //     duration: 2,
        //     maxCount: 3,
        // });
        // this.$message.error(response.data);
    // }
    if (response.data.code === -1) {
        Modal.error({
            title: response.data.msg
        });
        console.log('报错', response.data.msg)
        return;

        // Storage.gtjn_token = "";
        // window.location = './#/login';
        // window.app.$router.push({path: "./#/login"});
        // return response.data;
    }
    if (response.data.code === -999) {
        // Modal.error({
        //     title: response.data.msg
        // });
        Storage.gtjn_token = "";
        console.log('登录失效踢回登录', response.data)
        window.app.$router.push({path: "/login"});
        Modal.error({
            title: response.data.msg
        });
        return response.data;
    }
    // // 口令错误跳转登录
    // if (response.data.code == Err.TOKEN_ERROR) {
    //     console.log('口令错误跳转到 登录', response.data)
    //     gotoLogin()
    //     return response.data;
    // }

    // 其他错误提示
    // console.log(ErrStr(response.data.code));
    return response.data;
}, error => {
    if (error.response) {

        message.info(error.response.data.message);
        // alert()
        return Promise.reject(error.response.data);
    }
}, function (error) {
    //处理不是200的结果
    if (401 === error.response.status) {
        window.app.$router.push({path: "/login"});

    } else {
        return Promise.reject(error);
    }

});