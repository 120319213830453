/**
 * 仓库
 */
export default {
    set gtjn_uid(value) {
        localStorage.setItem('gtjn_uid', value)
    },

    get gtjn_uid() {
        return localStorage.getItem('gtjn_uid')
    },

    set gtjn_token(value) {
        localStorage.setItem('gtjn_token', value)
    },

    get gtjn_token() {
        return localStorage.getItem('gtjn_token')
    },
    set gtjn_wx_code(value) {
        localStorage.setItem('gtjn_wx_code', value)
    },
    get gtjn_wx_code() {
        return localStorage.getItem('gtjn_wx_code')
    },

    set gtjn_account(value) {
        localStorage.setItem('gtjn_account', value)
    },
    get gtjn_account() {
        return localStorage.getItem('gtjn_account')
    },
    set gtjn_token_expire_time(value) {
        localStorage.setItem('gtjn_token_expire_time', value)
    },
    get gtjn_token_expire_time() {
        return localStorage.getItem('gtjn_token_expire_time')
    },

    set gtjn_roles(value) {
        localStorage.setItem('gtjn_roles', value)
    },
    get gtjn_roles() {
        return localStorage.getItem('gtjn_roles')
    },


}
